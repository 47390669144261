import { mainAPI } from '../../utils/apis';

export default {
  namespaced: true,
  state: {

  },
  mutations: {

  },
  actions: {
    createCampaign(_, payload) {
      return new Promise(function(resolve, reject) {
        const { name, description, keyword, thumbnail, caption, frame, visibility = 'public', enterCode, url } = payload;

        mainAPI.post('campaign', {
          name, description, keyword, thumbnail, frame, visibility, url, caption,
          ...(enterCode && { enterCode }),
        })
          .then(({data}) => {
            resolve(data)
          })
          .catch(({ response }) => {
            
            reject(response)
          })
      }) 
    },
    updateCampaign(_, payload) {
      return new Promise(function(resolve, reject) {
        const { name, description, keyword, thumbnail, caption, frame, visibility = 'public', enterCode, url, paramsUrl } = payload;

        mainAPI.patch(`campaign/${paramsUrl}`, {
          ...(name && { name }),
          ...(keyword && { keyword }),
          ...(thumbnail && { thumbnail }),
          ...(frame && { frame }),
          ...(visibility && { visibility }),
          ...(url && { url }),
          ...(enterCode && { enterCode }),
          caption,
          description,
        })
          .then(({data}) => {
            resolve(data)
          })
          .catch(({ response }) => {
            
            reject(response)
          })
      }) 
    },
    deleteCampaign(_, url) {
      return new Promise(function(resolve, reject) {
        mainAPI.delete(`campaign/${url}`, {})
          .then(({data}) => {
            resolve(data)
          })
          .catch(({ response }) => {
            
            reject(response)
          })
      }) 
    },
    getPresignedCampaign(_, payload) {
      const { frame, thumbnail } = payload;

      return new Promise(function(resolve, reject) {
        mainAPI.post('campaign/presign', {
          frame: {
            key: frame.key,
            fileSize: frame.fileSize,
          },
          thumbnail: {
            key: thumbnail.key,
            fileSize: thumbnail.fileSize,
          },
        })
          .then(({data}) => {
            resolve(data)
          })
          .catch(({ response }) => {
            
            reject(response)
          })
      }) 
    },
    getCampaigns(_, payload) {
      const { keyword, reactive = 0, numItems = 10, sort = 'newest', page } = payload;
      let query = '';
      

      keyword ? query += `keyword=${keyword}` : '';
      reactive >= 0 ? query += `&reactive=${reactive}` : '';
      numItems >= 0 ? query += `&numItems=${numItems}` : '';
      sort ? query += `&sort=${sort}` : '';
      page ? query += `&page=${page}` : '';

      return new Promise(function(resolve, reject) {
        mainAPI.get(`campaign/search?${query}`, {})
          .then(({data}) => {
            resolve(data)
          })
          .catch(({ response }) => {
            
            reject(response)
          })
      }) 
    },
    getCampaignDetails(_, payload) {
      const { url, code } = payload;

      return new Promise(function(resolve, reject) {
        mainAPI.get(`campaign/details/${url}`, null, {
          params: {
            ...(code && { code }),
          }
        })
          .then(({data}) => {
            resolve(data)
          })
          .catch(({ response }) => {
            
            reject(response)
          })
      }) 
    },
    getMyCampaigns(_, { page = 1, numItems = 10 }) {
      return new Promise(function(resolve, reject) {
        mainAPI.get(`campaign?page=${page}&numItems=${numItems}`, {})
          .then(({ data }) => {
            resolve(data)
          })
          .catch(({ response }) => {
            
            reject(response)
          })
      }) 
    },
    getCampaignDetailsBulk(_, payload) {
      const { urls } = payload;

      return new Promise(function(resolve, reject) {
        mainAPI.get(`campaign/details/${urls}?bulk=1`, null)
          .then(({data}) => {
            resolve(data)
          })
          .catch(({ response }) => {
            
            reject(response)
          })
      }) 
    },
  },
  getters: {

  },
};
