export const mutations = {
    OPEN_SIDEBAR(state){
      state.isSidebarOpen = true;
    },
    CLOSE_SIDEBAR(state){
      state.isSidebarOpen = false;
    },
    SHOW_ACCOUNT_DROPDOWN(state){
      state.isShowAccountDropdown = true;
    },
    HIDE_ACCOUNT_DROPDOWN(state){
      state.isShowAccountDropdown = false;
    },
    SET_CAMPAIGN(state, payload){
      state.campaign = payload;
    },
    SET_LAYOUT(state, { isNavbarShow, navbarColor, isFooterShow }){
      if (isNavbarShow === true || isNavbarShow === false) state.isNavbarShow = isNavbarShow;
      if (isFooterShow === true || isFooterShow === false) state.isFooterShow = isFooterShow; 
      navbarColor ? state.navbarColor = navbarColor : '';
    },
    SET_ERROR_MESSAGE(state, payload) {
      if (state.errMsg !== null) state.errMsg = JSON.stringify(payload);
    },
    SET_UPLOAD_PERCENTAGE(state, payload) {
      state.uploadPercentage = payload
    },
    SET_TWIBBON_SLIDE(state, payload) {
      state.twibbonSlide = payload
    },
    SET_TWIBBON_RESULT(state, payload) {
      state.twibbonResult = payload
    },
    SET_TWIBBON_URL(state,payload) {
      state.twibbonUrl = payload
    }
};