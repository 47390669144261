<template>
  <footer class="footer">
    <div class="footer__body">
      <div class="container">
      <div v-if="hasLineFooter" class="footer__line"></div>
        <div class="footer__container">
          <div class="footer__logo">
            <router-link to="/">
              <img src="/assets/images/logos/super-twibbon-logo.svg" alt="">
            </router-link>
          </div>
          <div class="footer__link d-flex w-100">
            <ul class="footer__list-items">
              <li>
                <a :href="$t('link.help')">{{ $t("footer.help-center") }}</a>
              </li>
              <li>
                <router-link to="/terms">{{ $t("footer.terms") }}</router-link>
              </li>
              <li>
                <router-link to="/privacy-policy">{{ $t("footer.privacy-policy") }}</router-link>
              </li>
            </ul>
            <select v-model="locale" @change="changeLang" class="footer__mobile-element footer__change-lang custom-select px-1 pr-3" id="changeLang">
              <option value="id" :selected="'id' === $i18n.locale">ID</option>
              <option value="en" :selected="'en' === $i18n.locale">EN</option>
            </select>
          </div>
        </div>
        <select v-model="locale" @change="changeLang" class="footer__desktop-element footer__change-lang custom-select px-1 pr-3" id="changeLang">
          <option value="id" :selected="'id' === $i18n.locale">ID</option>
          <option value="en" :selected="'en' === $i18n.locale">EN</option>
        </select>
        <p class="copyright mt-3">© 2023 SuperTwibbon.</p>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
  data(){ 
    return { 
      hasLineFooter : true,
      locale: this.$i18n.locale
    }
  },
  methods: {
    changeLang(e) {
      this.$i18n.locale = e.target.value;
    },
  },
  watch: {
    locale: function(val){ 
      this.$i18n.locale = val;
      window.localStorage.setItem('preferred_locale', val);
    }
  },
  mounted(){ 
    const locale = window.localStorage.getItem('preferred_locale');

    if (locale) {
      this.$i18n.locale = locale;
      this.locale = locale;
    }

    if(/(about|signup|signin|contact-us)/i.test(this.$router.path)) {
      this.hasLineFooter = false
    }
  }
    
}
</script>

<style lang="scss">
  .footer {
    &__body {
      background: #FFFFFF;
      padding-top: 70px;
    }
    &__line {
      position: relative;
      width: 100%;
      height: 1px;
      background: #EAEAEA;
      bottom: 70px;
    }
    &__logo {
      margin-bottom: 1rem;

      img {
        height: 25px;
      }
    }
    &__list-items {
      margin-top: 8px;

      li {
        color: #E06544;
        margin-top: 16px;
        font-size: 16px;
      }
    }
    &__change-lang {
      width: 99px !important;
      height: 48px !important;
      display: flex;
      justify-content: center;
      text-align: center;
    }
    &__mobile-element {
      display: block;
    }
    &__desktop-element {
      display: none;
    }
    &__container {
      flex-direction: column;
    }
  }

  @media screen and (min-width: 768px) {
    .footer {
      &__mobile-element {
        display: none;
      }
      &__desktop-element {
        display: block;
      }
      &__container {
        flex-direction: row;
      }
      &__link {
        justify-content: end;
        text-align: right;
      }
    }
  }

  .select-language {
    &__text {
      font-size: 0.875rem;
      color: #6A7678;
      text-decoration: underline;
      font-weight: 700;
    }

    &__icon {
      font-size: 1.25rem;
      color: #6A7678;
    }
  }

  .pointer {
    cursor: pointer;
  }


</style>