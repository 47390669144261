<template>
  <header id="masthead" :class="['site-header', 'header-3', 'both-types', 'no-transition', color]">
    <transition name="slide" style="z-index : 1000;">
      <div v-if="isSidebarOpen" class="mb_wrap">
          <div class="mb-overlay mb-visible">
              <div class="mb-overlay-bg"></div>
              <div class="mb-menu">
                  <div class="close">
                      <ion-icon @click="closeSidebar" class="close-mb-menu" name="close-outline"></ion-icon>
                  </div>
                  <!-- your list menu in mobile here -->
                  <router-link style="flex-shrink: 0 !important;" to="/login" v-if="!isLoggedIn" href="login" class="btn btn-primary btn-outline">{{ $t("login.login") }}</router-link>
                  <div @click="logout" v-if="isLoggedIn" style="flex-shrink: 0 !important;" class="btn btn-primary btn-outline">{{ $t("login.logout") }}</div>
                  <ul>
                    <li v-for="{ route, text },i in navigations" :key="i">
                      <router-link
                        class="w-100" 
                        :to="route"
                      >
                        {{ text }}
                      </router-link>
                    </li>
                  </ul>
              </div>
          </div>
      </div>
    </transition>
    <div class="container">
      <div class="header-wrap" style="z-index : 1" >
        <div class="header-wrap-inner">
          <div class="left-part">
            <div class="site-branding">
                <div class="site-title">
                  <transition name="fade">
                      <div v-if="!isSidebarOpen" class="logo">
                        <router-link class="nav-link" to="/" style="padding: 0;">
                          <img src="/assets/images/logos/super-twibbon-logo.svg " class="svg-logo" alt="SuperTwibbon">
                        </router-link>
                      </div>
                  </transition>
                </div>
            </div>
          </div>
          <div class="right-part right">
              <nav v-if="ifShowNavigation" id="site-navigation" class="main-nav with-counters visible">
                <div class="mbl-overlay ">
                  <div id="mega-menu-wrap" class="main-nav-container" role="navigation">
                    <ul id="primary-menu" class="menu">
                      <li v-for="{ route, text },i in navigations" :key="i"  class="mega-menu-item nav-item -0 has-submenu">
                        <router-link
                          :to="route"
                          class="menu-link main-menu-link item-title"
                          data-aos="fade-right"
                          data-aos-delay="50"
                        >
                          {{ text }}
                        </router-link>
                      </li>
                    </ul>
                  </div>
                </div>
              </nav>
              <div v-if="isLoggedIn" class="d-contents"> 
                  <div class="mbl-overlay d-inline-block">
                  <!-- Navigation -->
                  <transition name="fade">
                      <div v-if="!isSidebarOpen" class="main-nav-container" role="navigation">
                          <ul id="primary-menu" class="menu">
                              <!-- ======================== top link -->
                              <li @mouseover="showAccountDropdown" @mouseleave="hideAccountDropdown" class="mega-menu-item nav-item -0 has-submenu">
                                  <a v-on:click="goToAccount" class="menu-link main-menu-link item-title">
                                      <Avatar v-if="campaignCreator" size="sm" :src="campaignCreator.avatar" />
                                  </a>
                                  <!-- <div v-if="isShowAccountDropdown" class="sub-nav d-none d-lg-block visible">
                                      <ul class="menu-depth-1 sub-menu sub-nav-group" style="z-index : 1000;">
                                          <li class="mega-menu-item sub-nav-item -1">
                                              <router-link to="/account" class="menu-link sub-menu-link" :class="{ is_active: $route.path == '/account'}">
                                                      <span class="">
                                                      <ion-icon style="vertical-align : middle;" name="add" role="img" class="md hydrated" aria-label="add"></ion-icon> {{ $t("account-sidebar.new-campaign") }}</span>
                                              </router-link>
                                          </li>
                                          <li class="mega-menu-item sub-nav-item -1">
                                              <router-link to="/account" class="menu-link sub-menu-link" :class="{ is_active: $route.path == '/account'}">
                                                  <span class="">{{ $t("account-sidebar.manage-campaign") }}</span>
                                              </router-link>
                                          </li>
                                          <div class="has_line mb-30"></div>
                                          <li class="mega-menu-item sub-nav-item -1">
                                              <div style="padding: 10px 25px;" class="menu-link font-weight-bold text-uppercase sub-menu-link">
                                                  <span class=""> 
                                                      <ion-icon name="settings-outline" role="img" class="md hydrated" aria-label="settings outline"></ion-icon> 
                                                      {{ $t("account-sidebar.account-settings") }}
                                                  </span>
                                              </div>
                                          </li>
                                          <li class="mega-menu-item sub-nav-item -1">
                                              <router-link to="/account/profile" class="menu-link sub-menu-link" :class="{ is_active: $route.path == '/account/profile'}">
                                                  <span class="">{{ $t("account-sidebar.profile") }}</span>
                                              </router-link>
                                          </li>
                                      </ul>
                                  </div> -->
                              </li>
                          </ul>
                      </div>
                  </transition>
                  </div>
                  <div class="mbl-overlay d-inline-block">
                      <div v-on:click="logout" class="login-btn btn btn-primary btn-sm d-none d-md-inline-block btn-outline" data-aos="fade-right" data-aos-delay="250">
                          Log Out
                      </div>
                  </div>
              </div>
              <div v-else class="d-inline-block">                              
                <router-link style="margin-right : 0 !important;" class="login-btn nav-link btn btn-primary btn-sm d-none d-md-inline-block btn-outline" data-aos="fade-right" data-aos-delay="250" to="/login">
                  {{ $t("login.login") }}
                </router-link>
              </div>
              <!-- Menu Mobile -->
              <div class="d-inline-block">
                  <div class="clb-hamburger btn-round btn-round-light" tabindex="1">
                      <div class="mb_wrap">
                          <transition name="fade"> 
                              <div v-if="!isSidebarOpen" class="burger">
                                  <div @click="openSidebar" class="menu-toggle-icon">
                                      <div class="cb-menu-toggle">
                                          <div class="menu">
                                            <input type="checkbox">
                                            <div style="background-color:#E06544;" class="line-menu"></div>
                                            <div style="background-color:#E06544;" class="line-menu"></div>
                                            <div style="background-color:#E06544;" class="line-menu"></div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </transition> 
                      </div>
                  </div>
              </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template> 

<script>
import Avatar from '@/components/base/Avatar.vue'
import { mapGetters, mapState, mapMutations, mapActions } from 'vuex'

export default {
  name: 'Navbar',
  components : {
    Avatar
  },    
  props : {
    color: {
      type: String,
      default: '',
    },
    showNavigation: {
      type: Boolean,
      default: true,
    },
    showSignLogin: {
      type: Boolean,
      default: true,
    },
  },
  computed : {
    ...mapGetters({
      isLoggedIn: 'account/isLoggedIn',
    }),
    ...mapState({
      campaignCreator: (state) => state.account.campaignCreator,
      isSidebarOpen: (state) => state.isSidebarOpen,
      isShowAccountDropdown: (state) => state.isShowAccountDropdown,
    }),
    ifShowNavigation(){
      if(this.showNavigation !== null && this.showNavigation !== true) return false;
      else return true;
    },
    ifShowLogin(){
      if(this.showSignLogin !== null) return this.showSignLogin;
      else return true;
    },
    navigations() {
      return [
        {
          route: '/explore',
          text: this.$t('header.explore'),
        },
        {
          route: '/features',
          text: this.$t('header.features'),
        },
        {
          route: '/about',
          text: this.$t('header.about'),
        }
      ];
    },
  },
  methods : {
    goToAccount(){
        this.$router.push('/account')
    },
    logout() {
      this.requestLogout();
      this.$router.push('/');
    },
    ...mapMutations({
        openSidebar: 'OPEN_SIDEBAR',
        closeSidebar: 'CLOSE_SIDEBAR',
        showAccountDropdown: 'SHOW_ACCOUNT_DROPDOWN',
        hideAccountDropdown: 'HIDE_ACCOUNT_DROPDOWN'
    }),
    ...mapActions({
        requestLogout: 'account/logout'
    })
  }
}
</script>