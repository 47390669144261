<template>
  <picture class="super-avatar avatar rounded-circle">
    <source v-if="src" :srcset="avatarUrl">
    <img
      src="/assets/images/illustration/super-twibbon-avatar.jpg"
      alt="..."
      class="super-avatar avatar rounded-circle"
      :class="`avatar-${size}`"
    > 
  </picture>
</template>


<script>
export default { 
  name : 'Avatar',
  computed: {
    avatarUrl() {
      return `${process.env.VUE_APP_AVATAR_URL}/${this.src}`;
    }
  },
  props: {
    src: {
      type: String,
    },
    size: {
      type: String,
    },
  },
}
</script>

<style>
  .super-avatar {
    display: block;
    background: white;
  }
</style>