import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store/index';

const Home = () => import(/* webpackChunkName: "home" */ '../views/HomePage/index.vue')  
const Explore = () => import(/* webpackChunkName: "explore" */ '../views/Explore.vue') 
const SuperCampaign = () => import(/* webpackChunkName: "campaign" */ '../views/SuperCampaign')
const Features = () => import(/* webpackChunkName: "features" */ '../views/Features.vue')
const Terms = () => import(/* webpackChunkName: "terms" */ '../views/Terms.vue')
const PrivacyPolicy = () => import(/* webpackChunkName: "privacy-policy" */ '../views/PrivacyPolicy.vue')
const About = () => import(/* webpackChunkName: "about" */ '../views/About.vue')
const NotFound = () => import(/* webpackChunkName: "not-found" */ '../views/NotFound.vue')

const CampaignCreation = () => import(/* webpackChunkName: "new-campaign" */ '../views/account/CampaignCreation.vue')
const ManageCampaign = () => import(/* webpackChunkName: "manage-campaign" */ '../views/account/ManageCampaign.vue')
const Settings = () => import(/* webpackChunkName: "settings" */ '../views/account/Settings.vue')
const Login = () => import(/* webpackChunkName: "login" */ '../views/account/login/Login.vue')
const Account = () => import(/* webpackChunkName: "account" */ '../views/account/Account.vue')

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/explore',
    name: 'Explore',
    component: Explore
  },
  {
    path: '/features',
    name: 'Features',
    component: Features
  },
  {
    path: '/login',
    name: 'Login',
    component : Login,
    beforeEnter: redirectToAccount
  },
  {
    path: '/404', 
    name : 'NotFound', 
    component : NotFound
  },
  {
    path: '/account',
    component : Account,
    beforeEnter: redirectToHome ,
    children : [
      {
        name: 'ManageCampaign',
        path : '',
        component : ManageCampaign
      },
      {
        name: 'CampaignCreation',
        path : 'create',
        component : CampaignCreation
      },
      {
        name: 'Profile',
        path : 'profile',
        component : Settings
      },
      {
        name: 'EditCampaign',
        path: 'edit-campaign/:url',
        props: true,
        component : CampaignCreation
      },
    ]
  },
  {
    path: '/about',
    name: 'About',
    component: About
  },
  {
    path : '/terms',
    name : 'Terms',
    component : Terms
  },
  {
    path : '/privacy-policy',
    name : 'PrivacyPolicy',
    component : PrivacyPolicy
  },
  {
    path : '/:url',
    name: 'Campaign',
    component : SuperCampaign,
  },
  { path: "*", component: NotFound }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes : routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  }
})

router.beforeEach(async (to, from, next) => {
  let re = new RegExp("/avatar")

  if (from.name === null && Boolean(localStorage.getItem('st.auth.token')) || /account/ig.test(to.name)) {
    store.dispatch('account/refreshToken');
  }

  if (re.test(to.path)) next({ name: 'NotFound' })

  if (from.path === '/explore') {
    store.commit('SET_LAYOUT', { isFooterShow: true })
  }
  
  store.commit('CLOSE_SIDEBAR')
  store.commit('HIDE_ACCOUNT_DROPDOWN')
  next()
})

function redirectToHome (to, from, next) {
  if(localStorage.getItem('st.auth.token')) {
    next()
  } else {
    next({ name: 'Home' })
  }
}

function redirectToAccount (to, from, next) {
  if (localStorage.getItem('st.auth.token')) {
    next({ path: '/account' })
  } else {
    next()
  }
}

Vue.use({
  router
})

export default router
