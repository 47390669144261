import { mainAPI } from '../../utils/apis';
const isMobile = false;

export default {
  namespaced: true,
  state: {
    campaignCreator: {},
    isLogin: false,
  },
  mutations: {
    SET_CAMPAIGN_CREATOR (state, campaignCreator) {
      state.isLogin = true;
      state.campaignCreator = Object.assign({}, state.campaignCreator, campaignCreator)
    },
    UNSET_CAMPAIGN_CREATOR(state) {
      state.isLogin = false;
      state.campaignCreator = Object.assign({});
    },
  },
  actions: {
    login(_, payload) {
      const { email } = payload;
      
      return new Promise(function(resolve, reject) {
        mainAPI.post('user', { email })
          .then(({ data }) => {
            resolve(data)
          })
          .catch(({ response }) => {
            
            reject(response)
          })
      }) 
    },
    loginGoogle({ commit }, payload) {
      const { idToken } = payload;

      return new Promise(function(resolve, reject) {
        mainAPI.post('user/login/google', { idToken, isMobile })
          .then(({ data }) => {
            localStorage.setItem('st.auth.token', data.data.token);
            commit('SET_CAMPAIGN_CREATOR', data.data.user)
            resolve(data)
          })
          .catch(({ response }) => {
            
            reject(response)
          })
      }) 
    },
    verifyLogin({ commit }, payload) {
      const { email, otp } = payload;

      return new Promise(function(resolve, reject) {
        mainAPI.post('user/verify', { email, otp, case: payload.case, isMobile })
          .then(({ data }) => {
            localStorage.setItem('st.auth.token', data.data.token);
            commit('SET_CAMPAIGN_CREATOR', data.data.user)
            resolve(data)
          })
          .catch(({ response }) => {
            
            reject(response)
          })
      }) 
    },
    refreshToken({ commit, dispatch }) {
      return new Promise(function(resolve, reject) {
        mainAPI.post('user/refresh', {})
          .then(({ data }) => {
            commit('SET_CAMPAIGN_CREATOR', data.data.userData)
            resolve(data)
          })
          .catch(({ response }) => {
            dispatch('logout');
            
            reject(response)
          })
      }) 
    },
    resendOTP(_, payload) {
      const { email } = payload;

      return new Promise(function(resolve, reject) {
        mainAPI.post('user/verify/resend', { email, case: payload.case })
          .then((data) => {
            resolve(data)
          })
          .catch(({ response }) => {
            
            reject(response)
          })
      }) 
    },
    updateUser(_, payload) {
      const { name, avatar } = payload;

      return new Promise(function(resolve, reject) {
        mainAPI.patch('user', {
          ...(name && { name }),
          ...(avatar && { avatar }),
          isMobile,
        })
          .then((data) => {
            resolve(data)
          })
          .catch(({ response }) => {
            
            reject(response)
          })
      }) 
    },
    deleteAccount({ commit }) {
      return new Promise(function(resolve, reject) {
        mainAPI.delete('user', null)
          .then((data) => {
            localStorage.removeItem('st.auth.token');
            commit('UNSET_CAMPAIGN_CREATOR')
            resolve(data)
          })
          .catch(({ response }) => {
            
            reject(response)
          })
      }) 
    },
    getPresignedAvatar(_, payload) {
      const { key, fileSize } = payload;

      return new Promise(function(resolve, reject) {
        mainAPI.post('user/avatar/presign', { key, fileSize })
          .then(({ data }) => {
            resolve(data)
          })
          .catch(({ response }) => {
            
            reject(response)
          })
      }) 
    },
    logout({ commit }) {
      localStorage.removeItem('st.auth.token');
      commit('UNSET_CAMPAIGN_CREATOR');
    }
  },
  getters: {
    isLoggedIn(state) {
      return (Boolean(localStorage.getItem('st.auth.token')) && Boolean(state.campaignCreator.sub)) || state.isLogin;
    },
  },
};
