import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import { mutations } from './mutations';
import * as Cookies from "js-cookie";

import account from './account';
import campaign from './campaign';
import support from './support';
import trending from './trending';
import { noAPI } from '../utils/apis';

const dataState = createPersistedState({
  paths: ['twibbonResult', 'twibbonSlide', 'twibbonUrl', 'isAvatarLoaded', 'campaignCreator', 'avatar'],
  storage: {
    getItem: (key) => Cookies.get(key),
    setItem: (key, value) => Cookies.set(key, value, { secure: true }),
    removeItem: (key) => Cookies.remove(key),
  }
})

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    avatar: '',
    campaign: '',
    campaignCreator: '',
    errMsg: null,
    isSidebarOpen: false,
    navbarColor: '',
    isShowAccountDropdown: false,
    isNavbarShow: true,
    isFooterShow: true,
    uploadPercentage: 0,
    twibbonResult: '',
    twibbonSlide: 0,
    twibbonUrl: '',
    messageForgotPassword: 0
  },
  mutations,
  actions: {
    uploadS3(_, {
      fields, file, url,
    }) {
      const formData = new FormData();
  
      formData.append('X-Amz-Algorithm', fields['X-Amz-Algorithm']);
      formData.append('X-Amz-Credential', fields['X-Amz-Credential']);
      formData.append('X-Amz-Date', fields['X-Amz-Date']);
      formData.append('X-Amz-Signature', fields['X-Amz-Signature']);
      formData.append('key', fields.key);
      formData.append('Policy', fields.Policy);
      formData.append('bucket', fields.bucket);
      formData.append('file', file, fields.key);
  
      return new Promise((resolve, reject) => {
        noAPI.post(url, formData, {
          headers: {
            'Content-Disposition': `attachment; filename=${fields.key}`,
            'Content-Type': 'application/octet-stream',
            Authorization: ' ',
          },
        })
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response)
          });
      });
    },
  },
  plugins: [dataState],
  modules: {
    account,
    campaign,
    support,
    trending,
  },
})
