import { mainAPI } from '../../utils/apis';

export default {
  namespaced: true,
  state: {

  },
  mutations: {

  },
  actions: {
    supportHit(_, { url }) {
      return new Promise(function(resolve, reject) {
        mainAPI.post('supports/hit', { url })
          .then(({data}) => {
            resolve(data)
          })
          .catch(({ response }) => {
            
            reject(response)
          })
      }) 
    },
    getSupports(_, payload) {
      const { start = 0, limit = 0} = payload;

      return new Promise(function(resolve, reject) {
        mainAPI.get(`supports?start=${start}&limit=${limit}`)
          .then(({data}) => {
            resolve(data)
          })
          .catch(({ response }) => {
            
            reject(response)
          })
      }) 
    },
  },
  getters: {

  },
};
