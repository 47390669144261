<template>
  <div id="app">
    <div class="hero_page bg-like-navbar">
      <div class="site-header has_link_dark">
        <SuperNavbar v-if="isNavbarShow" :color="navbarColor"/>
      </div>
      <div v-if="isNavbarShow" class="spacer_header"/>
    </div>
    <router-view/>
    <SuperFooter v-if="isFooterShow"/>
  </div>
</template>

<script>
import SuperNavbar from '@/components/Navbar.vue';
import SuperFooter from '@/components/Footer.vue';
import { mapState, mapMutations } from 'vuex';

export default { 
  name: "App",
  components: {
    SuperNavbar,
    SuperFooter
  },
  computed: {
    ...mapState({
      isNavbarShow: 'isNavbarShow',
      isFooterShow: 'isFooterShow',
      navbarColor: 'navbarColor',
      errMsg: 'errMsg'
    })
  },
  methods: {
    ...mapMutations({
      SET_ERROR_MESSAGE: 'SET_ERROR_MESSAGE'
    })
  },
  created() {
    window.addEventListener('beforeunload', this.closeHandler)
  },
  watch: {
    errMsg(value) {
      if (value) {
        setTimeout(() => {
          this.$toast.error(this.$t('error.timeout'), {
              position : 'bottom', 
              duration : 2000
          })
        }, 2000)
        this.SET_ERROR_MESSAGE(null)
      }
    }
  }
}
</script>

<style lang="scss">
  .close-modal {
    color: #77748f !important;
    font-size: 24px;

    &:hover {
      cursor: pointer;
    }
  }
  
</style>
