import Vue from 'vue'
import App from './App.vue'

import VueSocialSharing from 'vue-social-sharing'
import VueCroppie from 'vue-croppie'
import VueClipboard from 'vue-clipboard2'
import VueMeta from 'vue-meta'
import VModal from 'vue-js-modal'
import VueToast from 'vue-toast-notification'
import VueCookies from 'vue-cookies'
import Ads from 'vue-google-adsense'
import browserDetect from 'vue-browser-detect-plugin'
import VueI18n from 'vue-i18n'
import VueContentPlaceholders from 'vue-content-placeholders'
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate'
import { required, max, email } from 'vee-validate/dist/rules';
import { VueHammer } from 'vue2-hammer';

import languages from './languages.json'
import router from './router'
import store from './store';

import 'croppie/croppie.css' // import the croppie css manually
import 'vue-toast-notification/dist/theme-default.css'

Vue.config.productionTip = false

Vue.use(VueMeta)
Vue.use(VueCroppie)
Vue.use(VueSocialSharing)
Vue.use(VueClipboard)
Vue.use(VueToast, {
  position: 'bottom',
  duration: 2000,
});
Vue.use(VModal)
Vue.use(VueCookies)
Vue.use(browserDetect)
Vue.use(require('vue-script2'))
Vue.use(Ads.Adsense)
Vue.use(Ads.InArticleAdsense)
Vue.use(VueI18n)
Vue.use(VueContentPlaceholders)
Vue.use(VueHammer)

Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);

Vue.config.ignoredElements = [/^ion-/];
Vue.config.productionTip = false

const i18n = new VueI18n({
  locale: 'id', 
  messages: languages,
})

extend('email', email);
extend('required', required);
extend('max', max);
extend('usedurl', {
  validate(value) {
    const usedUrl = [ '404', 'about', 'account', 'button-module', 'campaign', 'career', 'coming-soon', 'contact-us', 'cookie-policy', 'create-campaign', 'details', 'email-verification', 'explore', 'forgot-password', 'frame-module', 'guide', 'home', 'help-center', 'index', 'ingest', 'login', 'logout', 'maintenance', 'marketplace', 'my-campaigns', 'preview', 'pricing', 'privacy-policy', 'recent', 'register', 'reset-password', 'search', 'tags', 'terms', 'unsubscribe', 'url-availability', 'users',];
    for (let i = 0; i < usedUrl.length; i += 1) {
      if (value === usedUrl[i]) {
        return false;
      }
    }
  },
  message: () => i18n.t('account-new-campaign.url-not-available'),
});
extend('campaignurl', {
  validate(value) {
    if (/^[a-zA-Z0-9]+(-[a-zA-Z0-9]+)*$/.test(value)) { return true; }
    return false;
  },
  message: () => i18n.t('account-new-campaign.url-cant-contain'),
});

new Vue({
  i18n,
  router,
  store,
  render: h => h(App),
}).$mount('#app')
