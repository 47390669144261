import { mainAPI } from '../../utils/apis';

export default {
  namespaced: true,
  state: {

  },
  mutations: {

  },
  actions: {
    trendingHit(_, payload) {
      const { url } = payload;

      return new Promise(function(resolve, reject) {
        mainAPI.post('trending/hit', { url })
          .then(({data}) => {
            resolve(data)
          })
          .catch(({ response }) => {
            
            reject(response)
          })
      }) 
    },
    getTrending(_, payload) {
      const { start = 0, limit = 0, select = 1} = payload;

      return new Promise(function(resolve, reject) {
        mainAPI.get(`trending?start=${start}&limit=${limit}&select=${select}`)
          .then(({data}) => {
            resolve(data)
          })
          .catch(({ response }) => {
            
            reject(response)
          })
      }) 
    },
  },
  getters: {

  },
};
