import Axios from 'axios';

export const mainAPI =  Axios.create({
  baseURL: `${process.env.VUE_APP_API_URL}`,
  timeout: 60000,
});

mainAPI.interceptors.request.use(
  (config) => {
      const token = localStorage.getItem('st.auth.token');

      if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
      }

      return config;
  },

  (error) => {
      return Promise.reject(error);
  }
);


export const noAPI = Axios.create({
  timeout: 60000,
})